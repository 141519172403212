* {
  outline: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import "toastr";

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

/*!
 * bsStepper v1.7.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - 2019 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
 .bs-stepper .step-trigger{display:-ms-inline-flexbox;display:inline-flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:5px;font-size:1rem;font-weight:700;line-height:1.5;color:#6c757d;text-align:center;text-decoration:none;white-space:nowrap;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:transparent;border:none;border-radius:.25rem;transition:background-color .15s ease-out,color .15s ease-out}
 .bs-stepper .step-trigger:not(:disabled):not(.disabled){cursor:pointer}
 .bs-stepper .step-trigger.disabled,.bs-stepper .step-trigger:disabled{pointer-events:none;opacity:.65}
 .bs-stepper .step-trigger:focus{color:#007bff;outline:0}
 .bs-stepper .step-trigger:hover{text-decoration:none;background-color:rgba(0,0,0,.06)}
 @media (max-width:520px) {
 .bs-stepper .step-trigger{-ms-flex-direction:column;flex-direction:column;padding:10px}
 }
 .bs-stepper-label{display:inline-block;margin:.25rem}
 .bs-stepper-header{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}
 @media (max-width:520px) {
 .bs-stepper-header{margin:0 -10px;text-align:center}
 }
 .bs-stepper .line,.bs-stepper-line{-ms-flex:1 0 15px;flex:1 0 15px;min-width:1px;min-height:1px;margin:auto;background-color:rgba(0,0,0,.12)}
 @media (max-width:400px) {
 .bs-stepper .line,.bs-stepper-line{-ms-flex-preferred-size:20px;flex-basis:20px}
 }
 .bs-stepper-circle{display:-ms-inline-flexbox;display:inline-flex;-ms-flex-line-pack:center;align-content:center;-ms-flex-pack:center;justify-content:center;width:2em;height:2em;padding:.5em 0;margin:.25rem;line-height:1em;color:#fff;background-color:#6c757d;border-radius:1em}
 .active .bs-stepper-circle{background-color:#007bff}
 .bs-stepper-content{padding:0 20px 20px}
 @media (max-width:520px) {
 .bs-stepper-content{padding:0}
 }
 .bs-stepper.vertical{display:-ms-flexbox;display:flex}
 .bs-stepper.vertical .bs-stepper-header{-ms-flex-direction:column;flex-direction:column;-ms-flex-align:stretch;align-items:stretch;margin:0}
 .bs-stepper.vertical .bs-stepper-pane,.bs-stepper.vertical .content{display:block}
 .bs-stepper.vertical .bs-stepper-pane:not(.fade),.bs-stepper.vertical .content:not(.fade){display:block;visibility:hidden}
 .bs-stepper .content:not(.fade),.bs-stepper-pane:not(.fade){display:none}
 .bs-stepper .content.fade,.bs-stepper-pane.fade{visibility:hidden;transition-duration:.3s;transition-property:opacity}
 .bs-stepper .content.fade.active,.bs-stepper-pane.fade.active{visibility:visible;opacity:1}
 .bs-stepper .content.active:not(.fade),.bs-stepper-pane.active:not(.fade){display:block;visibility:visible}
 .bs-stepper .content.dstepper-block,.bs-stepper-pane.dstepper-block{display:block}
 .bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,.bs-stepper:not(.vertical) .content.dstepper-none{display:none}
 .vertical .bs-stepper-pane.fade.dstepper-none,.vertical .content.fade.dstepper-none{visibility:hidden}
 .testimonial-slider .swiper-button-prev{border-radius:50%;background:#24292d;color:#fff;margin:0;padding:0;width:50px;height:50px;display:flex;align-items:center;justify-content:center;transform:translate(-100px,-50%);transition:all .3s ease}
 .testimonial-slider .swiper-button-prev:after{font-size:14px;font-weight:600}
 .testimonial-slider .swiper-button-next{border-radius:50%;background:#24292d;color:#fff;margin:0;padding:0;width:50px;height:50px;display:flex;align-items:center;justify-content:center;transform:translate(100px,-50%);transition:all .3s ease}
 .testimonial-slider .swiper-button-next:after{font-size:14px;font-weight:600}
 .testimonial-slider:hover .swiper-button-prev,.testimonial-slider:hover .swiper-button-next{transform:translate(0,-50%)}
 .bs-stepper-header{justify-content:space-between}
 .bs-stepper-header .step{position:relative}
 .bs-stepper-header .step:before{position:absolute;content:'';width:20px;height:1px;background:#c7c7c7;top:50%;left:60px}
 .bs-stepper-header .step:last-child::before{display:none}

 .banner-section{padding:80px 0;height:730px;color:#fff;font-family:'Inter',sans-serif;overflow:hidden;z-index:1}
 .banner-section h1,.banner-section h4,.banner-section h6{color:#fff}
 .banner-section .sub-text{text-shadow:0 3.59567px 3.59567px rgba(0,0,0,0.1);font-size:44px}
 .banner-section .heading{font-size:100px;text-shadow:0 3.59567px 3.59567px rgba(0,0,0,0.1)}
 .banner-section .des{font-weight:400;font-size:23px}
 .banner-section .btn-order{background:linear-gradient(360deg,#2DDE98 0%,#2DF8A8 100%);box-shadow:inset 0 -4px 4px rgba(255,255,255,0.2);border-radius:4px;border:none;color:var(--bs-dark)}
 .banner-section .btn-order.alt{border:1px solid var(--bs-body-bg);color:var(--bs-body-bg);background:transparent;box-shadow:none}
 .banner-section:before{position:absolute;content:'';width:100%;height:100%;background:linear-gradient(-270deg,#3369E7 0%,rgba(51,105,231,0.3) 115.03%);top:0;z-index:-1}
 .banner-section:after{position:absolute;content:'';bottom:0;left:0;width:100%;background-image:url(../public/assets/images/wave.svg);height:180px;background-size:cover;background-repeat:no-repeat;background-position:top center;z-index:-1}

 .banner-section .banner-action{display: flex;align-items: center;flex-wrap: wrap;margin: 40px 0 0;gap: 20px;}

 .how-it-works-section .content-part .description{font-size:14px;font-weight:400;margin-top:10px;line-height:1.2}
 .how-it-works-section .course-feature-view{display:flex;margin:0 -20px}
 .how-it-works-section .course-feature-view .course-item{width:100%;max-width:calc(25% - 20px);flex-direction:column;margin:20px}
 .how-it-works-section .course-feature-view .course-item:nth-child(1) .icon{background:#2DF0A3}
 .how-it-works-section .course-feature-view .course-item:nth-child(2) .icon{background:#4FC1DE}
 .how-it-works-section .course-feature-view .course-item:nth-child(3) .icon{background:#3369E7}
 .how-it-works-section .btn-order{background:linear-gradient(360deg,#2DDE98 0%,#2DF8A8 100%);box-shadow:inset 0 -4px 4px rgba(255,255,255,0.2);border-radius:4px;border:none;color:var(--bs-dark);height:45px;display:flex;align-items:center;justify-content:center;font-size:16px;font-weight:700}
 .how-it-works-section .course-feature-view .course-item .icon{width:80px;height:80px;border-radius:50%;display:flex;align-items:center;justify-content:center;font-size:34px;margin-right:auto}
 .how-it-works-section .course-part{box-shadow:0 0 30px rgba(0,0,0,0.1);border-radius:15px;background:#fff;margin-left:-100px;padding:40px 60px}
 .how-it-works-section .image-part{height:670px;overflow:hidden;border-radius:20px}
 .how-it-works-section .image-part img{width:100%;height:100%;object-fit:cover}
 .how-it-works-section .call-action{font-size:20px;color:#3B3C3D;display:flex;align-items:center}
 .how-it-works-section .call-action span{width:31px;height:31px;border:1px solid #01ADFF;color:#01ADFF;border-radius:50px;display:flex;align-items:center;justify-content:center;font-size:14px}
 .how-it-works-section .call-action .text{width:100%;max-width:calc(100% - 40px);margin-left:10px}
 .bs-stepper-content .content.fade{display:none}
 .bs-stepper-content .content.fade.active{display:block}
 .video-section-part .card-header{position:sticky;top:0;z-index:1}
 .video-section-part .card-body.course-list-view{height:unset}
 .video-section-part .card-footer{position:sticky;bottom:0;z-index:1}
.badge{
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 90%!important;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: gray;
}
.badge.badge-warning{background-color: #ff8307;color: white;}

@media screen and (max-width:480px) {
  .banner-section .banner-action{gap: 10px;}
  .how-it-works-section{padding: 40px 0 !important;}
  .how-it-works-section .image-part{display: none;}
}


/* ============Modified Css Extra */

.whitespace-nowrap{white-space: nowrap;}
span.testimonial_description p{display:inline}
.video-container{width:100%;max-width:100%;transition:all .3s ease}
.video-container.active{max-width:calc(100% - 400px)}
.sidebar-sec{transform:translateX(100%);transition:all .3s ease;position:fixed;top:0;right:0;width:100%;max-width:400px;height:100%;z-index:2}
.sidebar-sec.active{transform:translateX(0%)}
.sidebar-sec .collapse.collapse-horizontal{transform:translateX(100%);transition:all .3s ease}
.sidebar-sec .collapse.collapse-horizontal.show{transform:translateX(0)}
.accordion-collapse.collapse{max-height:0;transition:all .35s ease;opacity:0;visibility:hidden;display:block}
.accordion-collapse.collapse.show{max-height:2000px;opacity:1;transition:all .35s ease;visibility:visible}
.contact-us-section{padding:80px 0;height:320px;color:#fff;font-family:'Inter',sans-serif;overflow:hidden;z-index:1;position:relative;background-repeat:no-repeat;background-size:cover;background-position: center;}
.contact-us-section:before{position:absolute;content:'';width:100%;height:100%;background:linear-gradient(to left,#3369E7 19.22%,#01ADFF 53.55%,#2DDE98 96.45%);top:0;z-index:-1;opacity:.8}
.contact-us-section:after{position:absolute;content:'';bottom:0;left:0;width:100%;background-image:url(../public/assets/images/wave.svg);height:180px;background-size:cover;background-repeat:no-repeat;background-position:top center;z-index:-1}
.contact-us-section .text-part .sub-text{font-size:20px;font-weight:700}
.contact-us-section .text-part .heading{color:#fff;font-size:40px;font-weight:700}
.contact-form-section{padding:80px 0;position:relative}
.contact-form-section:before{position:absolute;content:'';top:0;left:-420px;width:100%;background-image:url(http://localhost:3000/static/media/map-vector.b3f8aba8e1f3b2eb4a36.svg);height:400px;background-size:contain;background-repeat:no-repeat;background-position:top center;z-index:-1}
.contact-form-section .contact-information{background:white;box-shadow:0 0 30px rgba(0,0,0,0.15);border-radius:15px;padding:30px;text-align:left}
.contact-form-section .contact-information .data-row+ .data-row{margin-top:15px}
.contact-form-section .data-row{border-radius: 15px;padding: 30px 40px;}
.contact-form-section .contact-information .data-row .data-title{font-size:18px;font-weight:700;color:var(--bs-black);margin-bottom:15px;text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);}
.contact-form-section .contact-information .data-row .data-item{display:flex;align-items:center}
.contact-form-section .contact-information .data-row .data-item+ .data-item{margin-top:15px}
.contact-form-section .contact-information .data-row .data-item .icon{width:24px;height:24px;display:flex;align-items:center;justify-content:center;background:#2DF0A3;border-radius:50%;color:var(--bs-black);font-size:12px}
.contact-form-section .contact-information .data-row .data-item .icon.alt{background-color:#4FC1DE}
.contact-form-section .contact-information .data-row .data-item .text{font-size:14px;width:100%;max-width:calc(100% - 36px);color:#3B3C3D;font-weight:400;margin-left:12px;}
.contact-form-section .contact-address{margin-top:25px;background:#1D7ACA;padding:30px 40px;border-radius:15px;color:#fff}
.contact-form-section .contact-address .data-row{padding:0}
.contact-form-section .contact-address .data-row .data-title{color:#fff;text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);}
.contact-form-section .contact-address .data-row .data-item .text{color:#fff}
.login_btn{background-color:#007bff;border-radius:5px;color:white;border:0;padding:8px 16px;transition:all .3s ease}
.login_btn .icom{margin-right:10px}
.login_btn:hover{background-color:#007bff85;transition:all .3s ease}
.site-icon-logo{width:240px;display:inline-block;margin:0 0 20px}
.course-details-sec-item > .card img{height:100%;width:100%;object-fit:cover;object-position:center}

.course-details-sec-item .price div > span{font-size: 18px;}
/* .course-details-sec-item .price div:first-child span{background-color: #007bff;border-radius: 5px;}
.course-details-sec-item .price div:last-child{font-size: 20px;} */
.course-details-price > div:first-child span{color: inherit;font-size: 16px;}
.course-details-price > div:last-child{font-size: 20px;}
/* .course-details-price{width: 200px;} */
.course-details-price .text-loader{width: 40px;background-color: rgba(202, 202, 202, 0.451)!important;}

.table-bg-blue th{background-color: rgba(29, 59, 83);color: white;}
.resend-mail-btn.active{color: #066ac9;background-color: rgba(6, 106, 201, 0.1);border: 0;padding: 10px 16px;border-radius: 5px;}
.resend-mail-btn.active:hover{color: #fff;background-color: #066ac9;border-color: #066ac9;}
.resend-mail-btn:not(.active){pointer-events:none;color: #066ac9;background-color: rgba(6, 106, 201, 0.1);border: 0;padding: 10px 16px;border-radius: 5px;}
.timer{color: #066ac9}
.login-background-sec{position: relative;}
.login-background-image{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: -1;background-image: url('/public/assets/images/element/login-banner.png'), linear-gradient(-150deg ,#3369E7,#01ADFF,#2DDE98);background-repeat: no-repeat;background-size: cover;background-position: left center;background-blend-mode: multiply;}
.floating-quiz{position: absolute;right: 30px;bottom: 70px;border: 1px solid #066ac9;z-index: 2;background-color: white;border-radius: 5px;}

/* =================user dashboard====================> */

.add-user-btn{border-radius: 4px;padding: 0 10px;transition: all 0.30s ease;height: 40px;border: 0; font-weight: 500; background-color: var(--bs-primary);color: var(--bs-btn-color); font-size: 16px;}
.add-user-btn span{font-size: 14px;}
.add-user-btn:hover{background-color: rgba(var(--bs-primary-rgb), 0.2);color: var(--bs-primary);}
/* ========LOADER ANIMATION DESIGN============ */

.loader-h-15{height: 15px;}
.loader-h-25{height: 25px;}
.loader-h-35{height: 35px;}
.loader-h-75{height: 75px;}
.loader-h-240{height: 240px;}
.loader-h-324{height: 324px;}
.loader-w-75{width: 75px;}
.loader-w-240{width: 240px;}
.loader-w-324{width: 324px;}
.loader-w-424{width: 424px;}

.fadeoutAnimate{
  animation: fade_out 2s ease infinite;
}

@keyframes fade_out{
  0%{opacity: 1;}
  50%{opacity: 0.4;}
  100%{opacity: 1;}
}


.loader-bg-light{background-color:#ebf2f8!important;}
.progress {
  width: 230px;
}

.section-min-height{min-height: calc(100vh - 200px);}
.btn-back{width: 32px;min-width: 32px;height: 32px;padding: 0;border: 1px solid var(--bs-primary);background-color: transparent;color: var(--bs-primary);border-radius: 50%;font-size: 14px;}

.btn-signin{display: flex;align-items: center;justify-content: center;gap: 8px;height: 40px;border-radius: 4px;padding: 0 12px;font-size: 20px;}
.btn-signin .text{font-size: 14px; font-weight: 500;}
.userdropdown .dropdown-menu{padding: 10px 0; min-width: 240px;}
.userdropdown .username{font-size: 16px;font-weight: 600;color: var(--bs-black);}

.modal-content{border-radius: 10px;}
.modal-header{background-color: var(--bs-primary);}
.modal-header .modal-title{color: var(--bs-white);}
.modal-header .btn-close{filter: invert(1);}
.modal-footer{background-color: var(--bs-gray-200); padding: 8px 16px; gap: 10px;}
.modal-footer > *{margin: 0;}

.userlist{position: relative; margin: 0 -20px;}
.userlist .userlist-item{display: flex; justify-content: space-between; flex-wrap: wrap; position: relative;padding: 12px 20px; background-color: var(--bs-white); transition: all 0.3s ease;}
.userlist .userlist-item:hover{background-color: var(--bs-gray-100);}
.userlist .userlist-item+.userlist-item{border-top:1px solid var(--bs-border-color);}
.userlist .userlist-item .userlist-item-info{width: 100%; max-width: calc(100% - 170px); }
.userlist .userlist-item .userlist-name{font-size: 16px;font-weight: 600;color: var(--bs-black);}
.userlist .userlist-item .userlist-meta{position: relative; margin: 4px 0 0;}
.userlist .userlist-item .userlist-meta-item{display: flex; column-gap: 10px; padding: 3px 0;}
.userlist .userlist-item .userlist-meta-label{font-size: 13px;color: var(--bs-body-color);line-height: 1;width: 70px;}
.userlist .userlist-item .userlist-meta-data{font-size: 13px;color: var(--bs-body-color);line-height: 1; width:calc(100% - 80px);}
.userlist .userlist-item .userlist-item-options{display: flex;flex-direction: column;justify-content: space-between;align-items: flex-end;width: 150px;}
.userlist .userlist-item .btn-edit{height: 30px;border: 0;background-color: transparent;font-size: 12px;font-weight: 500;color: var(--bs-primary); gap: 4px; display: flex; align-items: center;transition: all 0.3s ease;}
.userlist .userlist-item .btn-edit span{font-size: 13px;white-space: nowrap;}
.userlist .userlist-item .btn-action{height: 30px;display: flex;align-items: center;font-size: 12px;font-weight: 500;padding: 0 10px;border-radius: 4px;gap: 4px;white-space: nowrap;transition: all 0.3s ease;}
.userlist .userlist-item .btn-action span{font-size: 13px;white-space: nowrap;}


.courseslist{position: relative;margin: 0 -20px;}
.courseslist .courseslist-item{display: flex; justify-content: space-between; flex-wrap: wrap; position: relative;padding: 12px 20px;transition: all 0.3s ease;}
.courseslist .courseslist-item:hover{background-color: var(--bs-gray-100);}
.courseslist .courseslist-item+.courseslist-item{border-top:1px solid var(--bs-border-color);}
.courseslist .courseslist-item .courseslist-info{width: 100%; max-width: calc(100% - 150px); display: flex;flex-wrap: wrap; gap: 20px;}
.courseslist .courseslist-item .courseslist-image{width: 160px; height: 120px; overflow: hidden; border-radius: 4px;}
.courseslist .courseslist-item .courseslist-image img{width: 100%; height: 100%; object-fit: cover;}
.courseslist .courseslist-item .courseslist-content{width: 100%; max-width: calc(100% - 180px);}
.courseslist .courseslist-item .courseslist-title{font-size: 16px;font-weight: 600;color: var(--bs-black); display: inline-block; line-height: 1.2;}
.courseslist .courseslist-item .courseslist-title:hover{color: var(--bs-primary);}
.courseslist .courseslist-item .courseslist-meta{position: relative; margin: 4px 0 10px;}
.courseslist .courseslist-item .courseslist-meta-item{display: flex; column-gap: 10px; padding: 3px 0;}
.courseslist .courseslist-item .courseslist-meta-label{font-size: 13px;color: var(--bs-body-color);line-height: 1;width: 120px;}
.courseslist .courseslist-item .courseslist-meta-data{font-size: 13px;color: var(--bs-body-color);line-height: 1; width:calc(100% - 130px);}
.courseslist .courseslist-item .courseslist-progress{display: flex;align-items: center;gap: 10px;}
.courseslist .courseslist-item .progress{width: 100%;max-width: 200px;height: 12px;background-color: rgba(var(--bs-primary-rgb), 0.2);}
.courseslist .courseslist-item .progress-bar{background-color: var(--bs-primary);}
.courseslist .courseslist-item .progress-percentage{font-size: 14px;font-weight: 600;color: var(--bs-primary);}
.courseslist .courseslist-item .courseslist-options{display: flex;flex-direction: column;justify-content: space-between;align-items: flex-end;width: 130px;}
.courseslist .courseslist-item .btn-action{height: 30px;display: flex;align-items: center;font-size: 12px;font-weight: 500;padding: 0 10px;border-radius: 4px;gap: 4px;white-space: nowrap; border: 0;transition: all 0.3s ease;}
.courseslist .courseslist-item .btn-action span{font-size: 13px;white-space: nowrap;}

.no-result-found{position: relative;display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 60px;}
.no-result-found .no-result-found-image{width: 300px;margin: auto;}
.no-result-found .no-result-found-image img{width: 100%;height: 100%;object-fit: contain;}
.no-result-found .no-result-found-title{font-size: 20px;font-weight: 600;color: #2d56b3;}

.cStepper{position: relative;background-color: var(--bs-gray-100);border-bottom: 1px solid var(--bs-gray-300);padding:10px;}
.cStepper .cStepper-inner{display: flex; align-items: center; overflow:auto;padding:20px 0;}
.cStepper .cStepper-step{width: 100%;min-width:60px;display: flex;justify-content: center;position: relative;z-index: 0;}
.cStepper .cStepper-step:before{content:""; position: absolute;width: 100%;border-top: 1px solid var(--bs-primary);top: 50%;left: 50%;z-index:-1;}
.cStepper .cStepper-step:last-child:before{display:none}
.cStepper .cStepper-step-button{width: 40px;min-width: 40px;height: 40px;padding: 0;display: flex;justify-content: center;align-items: center;background-color: var(--bs-white);border: 1px solid var(--bs-primary);border-radius: 50%; position:relative; z-index: 1;}
.cStepper .cStepper-step-count{display: flex;align-items: center;justify-content: center;font-size: 16px;font-weight: 600;line-height: 1;margin: 1px 0 0;color: var(--bs-gray-900);}
.cStepper .cStepper-step.active .cStepper-step-button{background-color: var(--bs-primary);}
.cStepper .cStepper-step.active .cStepper-step-count{color: var(--bs-white);}
.cStepper-content .content{display:none;}
.cStepper-content .content.active{display:block;}
.answer-button {white-space: pre-wrap;line-height: 1.2;color: var(--bs-gray-900);font-weight: 500;letter-spacing: 0;min-height: 60px;text-align: left;display: flex;align-items: center;padding: 20px;}

/* ********|| COURSE DETAILS BANNER SECTION ||******* */

.course-details-contact .btn{margin-bottom: 0;}
.main-box{margin: 0 0 40px;}


.btn-custom{width:80%;position: absolute; left: 0; bottom: -15px; width: calc(100% - 20px); padding: 6px 10px; font-size: 12px; display: flex; align-items: center; justify-content: center; text-align: center !important; white-space: normal; word-break: break-word; margin: 0 10px;}
/* ********|| RESPONSIVE STARTS ||******** */

@media screen and (max-width: 1560px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 1200px) {
  .contact-price-sec{flex-direction: column-reverse;align-items: inherit!important;}
  .course-details-contact{width: 100%;}
  .course-details-price{margin-left: 0!important;margin-bottom: 10px;}
}

@media screen and (min-width: 1025px) {}

@media screen and (max-width: 1024px) {
  .course-list-view{height: auto;overflow: auto;}
  
}

@media screen and (max-width: 990px) {
  .banner-section .heading{font-size:40px}
  .banner-section .sub-text{font-size:24px}
  .banner-section .des{font-size:14px}
  .how-it-works-section .course-part{margin-left:unset;padding:30px}
  .how-it-works-section .course-feature-view{flex-wrap:wrap}
  .how-it-works-section .course-feature-view .course-item{max-width:calc(100% - 20px);flex-direction:unset}
  .how-it-works-section .course-feature-view .course-item .icon{width:50px;height:50px;padding:10px}
  .how-it-works-section .content-part .description{width:100%;max-width:calc(100% - 60px);margin-left:10px}

  .contact-form-section{padding: 40px 0;}
  .contact-us-section .text-part .sub-text{font-size: 16px;font-weight: 500;}
  .contact-us-section .text-part .heading{font-size: 28px;font-weight: 600;}
  .contact-form-section .contact-information{padding: 20px;}
  .contact-form-section .contact-information .data-row{padding: 10px;}
  .contact-form-section .contact-address{padding: 10px;}
  .contact-form-section .contact-information .data-row .data-item{align-items: flex-start;}
}

@media screen and (max-width: 767px) {
  .accordion-header .accordion-button{padding-left: 0 !important;}
}

@media screen and (max-width: 480px) {
  .accordion-header .accordion-button{padding-left: 0 !important;}
  .navbar > .container{gap: 0px;justify-content: flex-end;}
  .navbar .navbar-brand{margin-right: auto;}
  .banner-section{ display: flex; align-items: center;}
  .how-it-works-section .call-action{font-size: 16px;}
  .how-it-works-section .image-part{height: 400px;}
  .sidebar-sec{max-width:calc(100% - 42px);}
  .item-per-view{flex-wrap: wrap;}
  .item-per-view .question-part{max-width: 100%;margin: 0 0 16px;}
  .item-per-view .btn-attempt{font-size: 12px;height: 32px;width: 100%;}
  .btn-signin{padding: 0 8px;}
  .btn-signin span.text{display: none;}
  .add-user-btn{margin: 10px 0 0;}
  .userlist{margin: 0 -5px;}
  .userlist .userlist-item{padding:0; border:1px solid var(--bs-border-color); border-radius: 6px;}
  .userlist .userlist-item+.userlist-item{margin: 10px 0 0;}
  .userlist .userlist-item .userlist-item-info{max-width: 100%; padding:16px;}
  .userlist .userlist-item .userlist-item-options{width: 100%;flex-direction: row;align-items: center;gap:10px;border-top: 1px solid rgba(0,0,0,0.1);margin: 10px 0 0;padding: 10px 16px;}
  .userlist .userlist-item .userlist-item-options > *{width: 100%;justify-content: center;}

  .courseslist{margin: 0 -5px;}
  .courseslist .courseslist-item{padding: 0; border:1px solid var(--bs-border-color); border-radius: 6px;}
  .courseslist .courseslist-item+.courseslist-item{margin: 10px 0 0;}
  .courseslist .courseslist-item .courseslist-info{max-width: 100%; padding: 0;gap: 0;}
  .courseslist .courseslist-item .courseslist-image{width: 100%; height: 200px; border-radius: 6px 6px 0 0;}
  .courseslist .courseslist-item .courseslist-content{max-width: 100%;padding: 16px;}
  .courseslist .courseslist-item .courseslist-progress{justify-content: space-between; gap: 10px;}
  .courseslist .courseslist-item .progress{max-width: 100%;}
  .courseslist .courseslist-item .progress-percentage{min-width: 40px; text-align: right;}
  .courseslist .courseslist-item .courseslist-options{width: 100%;flex-direction: row;align-items: center; gap:10px; border-top: 1px solid rgba(0,0,0,0.1);margin:0;padding: 10px 16px;}
  .courseslist .courseslist-item .courseslist-options > *{width: 100%;}
  .courseslist .courseslist-item .btn-action{width:100%; justify-content: center;}
  

}

@media screen and (max-width: 320px) {}

/* ********|| RESPONSIVE ENDS ||******** */


/* ===============modal============== */

@media (min-width: 992px){
.user-modal-size{width: 650px!important;}
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff;}
.page_404  img{ width:100%;}
.four_zero_four_bg{background-image: url("/src/assets/images/Originals/404.svg");height: 400px;background-position: center;background-repeat: no-repeat;}
.four_zero_four_bg h1{font-size:80px;}
.four_zero_four_bg h3{font-size:80px;}		 
.link_404{color: #fff;padding: 10px 20px;background: #007bff;margin: 20px 0;display: inline-block;border-radius: 5px;border: 1px solid #007bff;border-color: transparent;transition:all 0.30s ease;}
.link_404:hover{border-color: #007bff;background-color: transparent;color: #007bff;transition:all 0.30s ease;}
.contant_box_404{ margin-top:-50px;}


/* ********|| CALL-TO-ACTION STARTS ||******** */
#root .sec-calltoaction{position: relative;z-index: 0;padding: 100px 0;font-family: 'Inter', sans-serif;}
#root .sec-calltoaction .background-image{width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: -1;}
#root .sec-calltoaction .background-image img{width: 100%; height: 100%; object-fit: cover;}
#root .sec-calltoaction .calltoaction-content{position: relative; width: 100%;}
#root .sec-calltoaction .calltoaction-content .title{font-size: 36px;font-weight: 800;color: var(--bs-black);line-height: 1;margin: 0 0 20px;}
#root .sec-calltoaction .calltoaction-content .description{font-size: 16px;line-height: 1.6;color: var(--bs-black);margin: 0 0 20px;}
#root .sec-calltoaction .calltoaction-content .actions{display: flex; align-self: center; margin: 40px 0 0;}
#root .sec-calltoaction .calltoaction-content .btn-action{background: linear-gradient(360deg,#2DDE98 0%,#2DF8A8 100%);border: 0;color: var(--bs-black);font-weight: 700;box-shadow: none;height: 44px;padding: 0 24px;display: flex;align-items: center;justify-content: center;}

@media screen and (max-width: 1366px) {
  #root .sec-calltoaction .calltoaction-content .title{font-size: 32px;}
  #root .sec-calltoaction .calltoaction-content .description{font-size: 15px;}
}
@media screen and (max-width: 1024px) {
  #root .sec-calltoaction{padding: 60px 0;}
  #root .sec-calltoaction .calltoaction-content .title{font-size: 28px;}
  #root .sec-calltoaction .calltoaction-content .description{font-size: 14px;}
  #root .sec-calltoaction .calltoaction-content .actions{margin: 30px 0 0;}
}
@media screen and (max-width: 640px) {
  #root .sec-calltoaction{padding: 60px 0;}
  #root .sec-calltoaction .background-image img{object-position: 62% center;}
  #root .sec-calltoaction .calltoaction-content .title{font-size: 20px;}
  #root .sec-calltoaction .calltoaction-content .description{font-size: 12px; font-weight: 500;}
  #root .sec-calltoaction .calltoaction-content .btn-action{font-size: 14px; padding: 0 20px;}
}
/* ********|| CALL-TO-ACTION ENDS ||******** */
